export default [
  {
    title: 'home',
    route: 'dashboard',
    icon: 'HomeIcon',
    resource: 'dashboard',
    action: 'read',
  },
  {
    title: 'customers',
    route: 'customers',
    icon: 'UsersIcon',
    resource: 'customers',
    action: 'read',
  },
  {
    title: 'collaterals',
    route: 'collaterals',
    icon: 'BriefcaseIcon',
    resource: 'collaterals',
    action: 'read',
  },
  {
    title: 'transactions',
    route: 'transactions',
    icon: 'FileTextIcon',
    resource: 'transactions',
    action: 'read',
  },
  {
    title: 'reports',
    route: 'reports',
    icon: 'ActivityIcon',
    resource: 'reports',
    action: 'read',
  },
]
