<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://beesummer.com"
        target="_blank"
      >BEESUMMER 夏蜂</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>
    <span class="float-md-right d-none d-md-block">
      <b-link
        target="_blank"
        :to="{ name: 'terms'}"
      >
        {{ $t('terms') }}
      </b-link>。<b-link
        target="_blank"
        :to="{ name: 'privacy' }"
      >
        {{ $t('privacy policy') }}
      </b-link>。
      <b-link
        target="_blank"
        href="https://drive.google.com/file/d/1s10Cc5F3W55sptqaKueHLhDDhnKs8xrD/view?usp=sharing"
      >
        {{ $t('user menu') }}
      </b-link>
      {{ '。' }}
      <b-link
        to="links"
        target="_blank"
      >
        {{ $t('contact us') }}
        <feather-icon
          icon="BookOpenIcon"
          size="21"
          class="text-danger stroke-current"
        />
      </b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
