<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <b-button
        v-if="backButtonInfo.buttonShow"
        variant="flat-dark"
        size="sm"
        @click="back"
      >
        <feather-icon
          icon="ChevronLeftIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('Back') }}</span>
      </b-button>
    </div>

    <b-navbar-nav
      v-if="user"
      class="nav align-items-center ml-auto"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.displayName }}
            </p>
            <span class="user-status">{{ user.email }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="user.photoURL"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'settings' }"
        >
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>{{ $t('settings') }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('logout') }}</span>
        </b-dropdown-item>

        <!-- <b-dropdown-divider /> -->
        <!-- 多店家 -->

        <!-- <b-dropdown-item
          link-class="d-flex align-items-center"
        >
          <b-button
            v-for="store in stores"
            :key="store.id"
            :variant="store.themeColor"
            class="rounded-circle rounded-text-btn mr-50"
            @click="storeClick()"
          >
            E
          </b-button>
        </b-dropdown-item> -->
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { auth } from '../../firebase'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BButton,
    // Navbar Components
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      stores: [],
      avatar: '',
    }
  },
  computed: {
    backButtonInfo() {
      return this.$store.state.app.navBackInfo
    },
    ...mapGetters({
      getStores: 'app/getStores',
      user: 'getUser',
    }),
  },
  watch: {
    getStores(stores) {
      this.$set(this, 'stores', stores)
    },
  },
  methods: {
    async logout() {
      const log = {
        type: 'access',
        action: 'logout',
        actionId: this.user.uid,
        uid: this.user.uid,
        sid: this.user.sid,
      }
      await store.dispatch('logUser', log)
      auth.doSignOut().then(() => {
        // Redirect to login page
        localStorage.removeItem('userData')
        this.$router.push({ name: 'login' })
      })
    },
    back() {
      this.$router.push({ name: this.backButtonInfo.backName })
    },
    storeClick() {
    },
  },
}
</script>

<style scoped>
.rounded-text-btn {
  width: 26px;
  height: 26px;
  padding: 0;
}
</style>
